import { useState, useEffect } from 'react'
import { getLocPhysicalAndCity as getValueTrackingParams } from '../helpers/adRefTracking'

export const useValueTrackPageSwap = ({ data }) => {
  const [homePageNode, setHomePageNode] = useState(
    data && data.allContentfulPage && data && data.allContentfulPage.edges && data.allContentfulPage.edges[0] && data.allContentfulPage.edges[0].node
  )
  useEffect(() => {
    const { campaigns } = getValueTrackingParams()
    switch (campaigns) {
      case 'remodels':
        setHomePageNode(data.campaignPages.edges[0].node)
        break
      case 'siding':
        setHomePageNode(data.campaignPages.edges[1].node)
        break
    }
  }, [homePageNode])
  return homePageNode
}
