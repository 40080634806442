import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'
 
import { GatsbyImage, getImage } from "gatsby-plugin-image";
const RecentBlogPosts = () => (
  <StaticQuery
    query={graphql`
      query RecentBlogPostsQuery {
        allContentfulBlogPost(
            sort: { fields: publishDate, order: DESC }
            limit: 6
        ) {
          edges {
            node {
                title
                publishDate(formatString: "MMMM Do, YYYY")
                slug
                heroImage {
                  gatsbyImageData(width: 400, quality: 70, placeholder: NONE)
                }
                metaDescription
                author {
                  name
                }
                category {
                  name
                }
            }
          }
        }
      }
    `} 
    render={(data) => {
      return (
        <div className="w-full">
          {data.allContentfulBlogPost.edges &&   data.allContentfulBlogPost.edges.length > 0 && (
          <div className="max-w-7xl  px-4 md:px-6 mx-auto">
            <h2 className=" text-3xl md:text-4xl font-display text-nuetral-900 font-bold mb-4">Recent Blog Posts</h2>
            <div className="grid grid-cols-2 gap-6 lg:grid-cols-4">
              {data.allContentfulBlogPost.edges.map((item, i) => (
                <Link
                  key={i}
                  to={`/blog/${item.node.slug}`}
                  className="bg-white rounded-xl overflow-hidden shadow hover:shadow-lg"
                >
                  <GatsbyImage
                    image={getImage(item.node.heroImage)}
                    style={{ height: "150px" }}
                    className="w-full"
                    alt={item.node.title}
                  />
                  <div className="p-3 py-4">
                    <span className="text-neutral-700 text-base">{item.node.publishDate}</span>
                    <span className=" font-semibold leading-tight text-neutral-900 block">{item.node.title} </span>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
        </div>
      )
    }}
  />
)

export default RecentBlogPosts
