import React, { useRef } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { OPEN_CONTACT_MODAL } from "../consts";
import Popup from "reactjs-popup";
import { X } from "react-feather";
import Button from "./atoms/Button";
import ContactForm from "../components/estimate-form";
import { Border } from "./Border";
import { Link } from "gatsby";

const FeaturedColumns = (data) => {
  const dataColumnsCount = data.data.map((item, index) => (
    <FeaturedColumn key={index} item={item} columnsCount={data.data.length} />
  ));
  return dataColumnsCount;
};

const FeaturedColumn = (data) => {
  const learnMoreRef = useRef(null);
  const buttonProps = {};
  const hasContactModalClass =
    data.item.classNames &&
    data.item.classNames.length > 0 &&
    data.item.classNames.filter((className) => className == OPEN_CONTACT_MODAL).length > 0;
  if (hasContactModalClass) {
    buttonProps.ref = learnMoreRef;
    if (learnMoreRef.current) {
      // learnMoreRef.current.click = e => e.preventDefault()
    }
  }
  // shadow-[0px_0px_0px_1px_rgba(9,9,11,0.07),0px_2px_2px_0px_rgba(9,9,11,0.05)]
  return (
    <div
      
      key={data.item.slug}
      className="flex flex-wrap items-center bg-white shadow-md  group overflow-hidden      rounded-xl"
    >
      <div className="w-full  overflow-hidden">
        <GatsbyImage
          className="group-hover:scale-105  transition ease-in-out duration-300"
          image={getImage(data.item.heroImage)}
          style={{ width: "100%", height: "250px", objectFit: "cover" }}
          alt={data.item.serviceTitle + " Contractor"}
        /> 
      </div>



<div className=" w-full w-full">
          <div className="flex-1 relative px-6 md:px-8 py-6">
            
              <div className=" flex justify-between items-center">
              
                <div> 
                  <h3 className="text-xl lg:text-2xl font-bold text-gray-900">
                    {data.item.featuredLabel ? data.item.featuredLabel : data.item.serviceTitle}
                  </h3>{" "}
                  <p className=" lg:text-base  mt-2 text-sm text-gray-500   line-clamp-4 font-normal ">
                    {data.item && data.item.shortExcerpt}
                  </p>{" "}
                </div>
              
              </div>
           
            <div className="flex items-center space-x-4 mt-8">
            <Button to={data.item.slug}   title={"Learn more about " + data.item.serviceTitle} className="border-neutral-100 border !py-2 !px-4 ">
              Learn More
              </Button>
              {/* <Button to={'/contact'} color="link" title={"Learn more about " + data.item.serviceTitle} className="!p-2 ">
              Get a Quote
              </Button>
         */}
              </div>
 
          </div>
          
        </div>

      
       

    </div>
  );
};

const FeaturedItems = ({ data }) => {
  return (
    <div className="bg-gradient-to-b from-gray-50 to-white border-b border-gray-100 relative z-10 8">
      <div className="max-w-7xl mx-auto relative py-12 px-4 sm:px-6  " aria-labelledby="contact-heading">
        <h2 className="sr-only" id="contact-heading">
          Featured Services
        </h2>
        
          <div className=" grid grid-cols-1 sm:grid-cols-1 gap-4 md:gap-8 lg:grid-cols-3">
            <FeaturedColumns data={data} />
          </div>
       
      </div>
    </div>
  );
};
export default FeaturedItems;
